.user-profiles-table {
    /* grid-template-columns: 3fr 1.4fr 1fr 1fr !important; */
    width: 70%;
    /* overflow-y: scroll; */
}

.go-back {
    cursor: pointer;
    text-align: left;
    margin: 24px 16px;
    width: 100px !important;
}

.settings-page-content {
    max-height: 100vh;
    min-height: 100vh;
    overflow: scroll;
    padding: 24px 0px;
}

.manage-user-container {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    background: #EBEBEB;
}

.manage-substaion-container {
    display: flex;
    flex-direction: column;
    padding: 32px;
    align-items: flex-start;
    background: #EBEBEB;
}

.manage-user-container .ABB_CommonUX_Datagrid__root>table>thead {
    grid-template-columns: 1fr 2.2fr 1.3fr 1.5fr 1.1fr 0.7fr 1.5fr !important
}

.manage-user-container .ABB_CommonUX_Datagrid__root>table>tbody {
    grid-template-columns: 1fr 2.2fr 1.3fr 1.5fr 1.1fr 0.7fr 1.5fr !important
}

.manage-substaion-container .substation-table.ABB_CommonUX_Datagrid__root>table>thead {
    grid-template-columns: 1.2fr 1.2fr 1.2fr 2fr 1.7fr 1.3fr !important
}

.manage-substaion-container .substation-table.ABB_CommonUX_Datagrid__root>table>tbody {
    grid-template-columns: 1.2fr 1.2fr 1.2fr 2fr 1.7fr 1.4fr !important
}

.get-user-profiles {

    font-size: 20px;
    font-weight: 500;
}

.user-profiles-table {
    width: 700;
    position: relative;
    background: #FFFFFF;
    height: 450;
}

.manage-sub-title {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 5px;
}



.delete-confirm-dialog-container {
    width: 400px !important;
    height: auto;
    position: absolute !important;
    top: 250px !important;
    left: 35% !important;
    display: flex !important;
    flex-direction: column !important;
    /* background-color: #3498db; */
    z-index: 100 !important;
}

.manage-sub-file {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 5px;
}

.manage-sub-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 10px;
}

.manage-sub-download {
    display: flex;
    flex-direction: row;
    /* margin-bottom: 10px;
    margin-top: 10px; */
}


/* .sub-delete {
    z-index: 10 !important;
    left: 35%;
    top: 30%;
    position: absolute !important
} */

.confirm-substation-edit {
    z-index: 10 !important;
    left: 10%;
    top: 50%;
    position: absolute !important
}

.substation-table tbody tr:only-child {
    opacity: 0.5;
}