.recharts-default-legend {
    padding: 0px !important;
    margin: 0px !important;
    text-align: right !important;
    text-transform: capitalize;
}

.dist-events .recharts-legend-wrapper {
    position: absolute;
    width: 270px !important;
    height: auto;
    left: 30% !important;
    top: -85px !important
}

.text-color-class {
    color: black;
}

.text-rotate {
    writing-mode: vertical-rl;
}

.recharts-tooltip-item {
    color: black !important;
}

.recharts-default-tooltip {
    text-align: left;
}

.recharts-tooltip-label {
    font-weight: 600;
}

.recharts-layer .recharts-cartesian-axis .recharts-xAxis .xAxis>.recharts-text .recharts-cartesian-axis-tick-value {
    font-size: 10px !important;
}

.dist-events .recharts-wrapper {
    height: 200px !important;
}


.recharts-wrapper.dist-event-chart {
    height: 200px !important;
}

.dreventstimeline {
    position: absolute;
    margin-top: -26px;
    margin-left: -5%;

}

.dist-events .recharts-wrapper>.recharts-surface {
    height: 100% !important;
    width: 100% !important;

}

.custom-tooltip {
    background-color: white !important;
    top: 10px;
    border: 1px solid black !important;
}

/* Extra Large screens 150 % zoom*/

/* Extra Large screens 100 % zoom*/

@media (max-width: 1920px) {

    .dist-events .recharts-wrapper {
        all: unset;
    }

    .dist-events .recharts-wrapper {
        position: relative;
        cursor: default;
        width: 270px;
        height: 170px;
    }

    .dist-events .recharts-legend-wrapper {
        position: absolute;
        width: 270px !important;
        height: auto;
        left: 30%;
        top: -85px !important
    }





    .dist-events .recharts-wrapper>.recharts-surface {
        height: 100% !important;
        width: 100% !important;

    }
}

/* Extra Large screens 125 % zoom*/
@media only screen and (max-width: 1536px) {
    .dist-events .recharts-wrapper {
        all: unset;
    }

    .dist-events .recharts-wrapper {
        position: relative;
        cursor: default;
        width: 270px;
        height: 170px;

    }

    .dist-events .recharts-legend-wrapper {
        all: unset;
    }

    .dist-events .recharts-legend-wrapper {
        position: absolute;
        width: 270px !important;
        height: auto;
        left: 5px !important;
        top: -85px !important
    }

    .dist-events .recharts-wrapper>.recharts-surface {
        height: 100% !important;
        width: 100% !important;

    }

}

/* Extra Large screens 150 % zoom*/
@media (max-width: 1440px) {
    .dist-events .recharts-wrapper {
        all: unset;
    }

    .dist-events .recharts-wrapper {
        position: relative;
        cursor: default;
        width: 270px;
        height: 170px;

    }

    .dist-events .recharts-legend-wrapper {
        position: absolute;
        width: 270px !important;
        height: auto;
        left: 5px;
        top: -85px !important
    }

    .dreventstimeline {
        position: absolute;
        display: contents;

    }

    .recharts-wrapper {
        position: relative;
        cursor: default;
        width: 254px;
        height: 170px;

    }

    .dist-events .recharts-wrapper>.recharts-surface {
        height: 100% !important;
        width: 100% !important;

    }

}