#circle-example {
    position: fixed !important;
}

/* 
.metric-red {
    color: #EB5757;
}

.metric-green {
    color: #27AE60;

} */
.metric-red-arrow {
    color: #EB5757;
}

.metric-green-arrow {
    color: #145A32;
}

.no-sub {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
}

.metric-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.metric-nochange {
    color: black;
    vertical-align: -7px !important
}

.map-metric-score {
    font-weight: 700;
    font-size: 28px;
    height: 40px;
}


.map-metric-name {
    font-size: 16px;
    font-weight: 500;
}

.map-metric-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.metric-down {
    color: #EB5757;
}

.metric-up {
    color: #27AE60;
}

.metric-red-arrow {
    color: #EB5757;
}

.metric-green-arrow {
    color: #196F3D;
}

#listsub {
    width: 200px;
    font-weight: 400;
    font-size: small;

}


.info-header {
    background: #ECECEC;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

}

.info-body {
    text-align: left;
    margin: 5px;
}

.info-button-header {


    background: #DBDBDB;
}

/* 
#listsub>p {
    float: left;
} */

.legend-container-full {
    position: absolute;
    bottom: 10px;
    left: 14%;
    display: flex;
    flex-direction: "row"
}

.legend-container-thumb {
    position: absolute;
    bottom: 10px;
    left: 8px;
    display: flex;
    flex-direction: "row"
}


#bedas-map-container .gm-style-iw .gm-style-iw-c {
    margin-top: -36px !important
}

.bedas-metric-box {
    background-color: white;
    padding: 10px;
    margin: 8px;
    width: 230px;
    height: 113px;
    min-width: 100px !important;
    min-height: 70px !important;
}

/* Extra Small screens */
@media (max-width: 575px) {

    /* CSS styles for extra small screens */
    .metric-stat {
        float: left;
        font-size: 19px;
    }

    #bedas-map-container .gm-style-iw .gm-style-iw-c {
        margin-top: -36px !important
    }
}

/* Small screens */
@media (min-width: 576px) {

    /* CSS styles for small screens */
    .metric-stat {
        float: left;
        font-size: 17px;
    }

    #bedas-map-container .gm-style-iw .gm-style-iw-c {
        margin-top: -36px !important
    }

    .bedas-metric-container {
        background-color: white;
        padding: 10px;
        margin: 8px;
        width: 100px;
        height: 70px;
    }
}

/* Medium screens */
@media (min-width: 768px) {

    /* CSS styles for medium screens */
    .metric-stat {
        float: left;
        font-size: 16px;
    }

    #bedas-map-container .gm-style-iw .gm-style-iw-c {
        margin-top: -36px !important
    }


}

/* Large screens */
@media (min-width: 992px) {

    /* CSS styles for large screens */
    .metric-stat {
        float: left;
        font-size: 15px;
    }

    #bedas-map-container .gm-style-iw .gm-style-iw-c {
        margin-top: -36px !important
    }
}

/* Extra Large screens 100 % zoom*/

@media (max-width: 1920px) {

    /* CSS styles for extra large screens */
    .metric-stat {
        float: left;
        font-size: 17px;
    }

    #bedas-map-container .gm-style-iw .gm-style-iw-c {
        margin-top: -36px !important
    }

    .bedas-metric-box {
        background-color: white;
        padding: 10px;
        margin: 8px;
        width: 230px;
        height: 113px;
        min-width: 100px !important;
        min-height: 70px !important;
    }

    /* .metric-name {
        font-size: 12px;
        font-weight: 500;
        color: black;
    } */

    /* .metric-stat {
        float: left;
        font-size: 13px;
    } */

    /* min-height: 136px; */
    /* #dashboard-metrics{
        padding: 16px 16px;
        display: flex;
        flex-direction: column;
        text-align: left;
       
        height: 115px;
    } */
    /* float: left; */
    /* .metric-score {
       
        width: 100%;
        float: left;
        font-size: 25px;
        font-weight: 800;
    } */


    /* #listsub {
        width: 200px;
        font-weight: 400;
        font-size: small;
        overflow-y: scroll;
        height: 70px;
    } */
}

/* Extra Large screens 125 % zoom*/

@media only screen and (max-width: 1536px) {

    /* Your CSS styles for screens up to 1536px width */
    .legend-container-full {
        position: absolute;
        bottom: 10px;
        left: 18%;
        display: flex;
        flex-direction: "row";
    }
}

/* Extra Large screens 150 % zoom*/

@media (max-width: 1440px) {

    .legend-container-full {
        position: absolute;
        bottom: 10px;
        left: 22%;
        display: flex;
        flex-direction: "row";
    }

    .legend-container-thumb {
        position: absolute;
        bottom: 10px;
        left: 2%;
        display: flex;
        flex-direction: "row";
    }


    .bedas-metric-box {
        background-color: white;
        padding: 10px;
        margin: 8px;
        width: 205px;
        height: 113px;

    }

    #listsub {
        width: 200px;
        font-weight: 400;
        font-size: small;
        overflow-y: scroll;
        height: 70px;
    }


}