.table-style {
  width: 100%;
  position: relative;
  height: 200px !important;
  background: #FFFFFF;
}

.overlay-dr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.dialog-container {
  max-width: 80%;
  /* Adjust the maximum width as needed */
}


.dr-filename {
  font-size: 12px;
}

.dist-records-rows {
  cursor: pointer;
  color: blue;
}

.table-style .ABB_CommonUX_Datagrid__smallRowHeight thead {
  grid-template-columns: 0.79fr 0.9fr 1.2fr 1fr !important
}

.table-style .ABB_CommonUX_Datagrid__smallRowHeight tbody {
  grid-template-columns: 0.79fr 0.9fr 1.2fr 1.2fr !important
}

.open-window .ABB_CommonUX_Icon__icon_abb_16 {
  font-size: 14px !important;
}

.acknowledged {
  color: #0CA919;
}


.charged {
  color: #0CA919;
  margin: 0px 5px;
}

.waiting {
  color: #a6a813;
  margin: 0px 5px;
}


.ack-button {
  text-align: center;
}



.ABB_CommonUX_Button__root.ABB_CommonUX_Button__smallSize {
  height: 32px;
  width: 123px;
}

.ABB_CommonUX_Dialog__header {
  text-align: left !important;
}

.unack {
  color: #0CA919;
}

.dr-confim-ack {
  z-index: 10 !important;
  position: absolute !important
}

/* Extra Large screens 150 % zoom*/
@media (max-width: 1440px) {
  .table-style .ABB_CommonUX_Datagrid__smallRowHeight thead {
    grid-template-columns: 1.21fr 0.9fr 1.2fr 1.2fr !important;
  }

  .table-style .ABB_CommonUX_Datagrid__smallRowHeight tbody {
    grid-template-columns: 1.21fr 0.9fr 1.2fr 1.2fr !important;
  }

}